import { render, staticRenderFns } from "./goodssalereturn.vue?vue&type=template&id=3f362548&scoped=true&"
import script from "./goodssalereturn.vue?vue&type=script&lang=js&"
export * from "./goodssalereturn.vue?vue&type=script&lang=js&"
import style0 from "./goodssalereturn.vue?vue&type=style&index=0&id=3f362548&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f362548",
  null
  
)

export default component.exports