<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>销售管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品销售退货订单</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.sr_code" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.s_status" placeholder="请选择是否作废" clearable  @change="selectstatusList">
            <el-option
                v-for="item in statusList"
                :key="item.s_status"
                :label="item.s_statusname"
                :value="item.s_status">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.sr_sid" placeholder="请选择退货员" @change="inputchange"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.add_uid" placeholder="请选择添加人" @change="inputchange"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-select v-model="querInfo.sr_payment_status" placeholder="请选择付款状态" @change="inputchange"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in outpaylist"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:6,offset:0}">
          <el-date-picker v-model="querInfo.sr_date" class="p_purchase_id" @change="inputchange"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择销售日期">
          </el-date-picker>
        </el-col>

        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary" @click="goAddSaleGoods" icon="el-icon-document-add" v-if="is_add">添加</el-button>
          <el-button type="primary" @click="DownloadGoodsSale" icon="el-icon-download" v-if="is_export">导出</el-button>
        </el-col>

      </el-row>
      <!--   用户列表区域   -->
      <el-table :data="saleList"  border stripe v-loading="loading">
        <el-table-column type="index" ></el-table-column>
        <el-table-column label="退款订单编号" prop="sr_code" width="200px"></el-table-column>
        <el-table-column label="销售订单编号" prop="sr_code" width="200px"></el-table-column>
        <el-table-column label="退货员" prop="sr_sale_name" width="80px" show-overflow-tooltip></el-table-column>
        <el-table-column label="退货时间" prop="sr_date" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="商品金额" prop="sr_price" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="其他金额" prop="sr_other_price" width="100px" show-overflow-tooltip></el-table-column>
        <el-table-column label="总金额"  width="100px" >
          <template slot-scope="scope">
            <div style="color: red;font-weight: bold;">{{scope.row.sr_total_price}}</div>
          </template>
        </el-table-column>
        <el-table-column label="添加人" width="90px" prop="add_uname" show-overflow-tooltip></el-table-column>
        <el-table-column label="付款状态" width="90px" prop="sr_payment_status_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="入库状态" width="90px" prop="sr_warehouse_status_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="是否作废">
          <template slot-scope="scope">
            <i class="el-icon-success" v-if="scope.row.sr_status==1" style="color: lightgreen"></i>
            <i class="el-icon-error" v-else style="color: red"></i>
          </template>
        </el-table-column>
        <el-table-column label="添加时间" width="150px" prop="add_date" show-overflow-tooltip></el-table-column>
        <!--    保质期    -->
        <el-table-column label="操作" width="250px" fixed="right">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="详情" placement="top" :enterable="true">
              <el-button type="primary" size="mini" icon="el-icon-tickets"
                         @click="saledetails(scope.row.sr_id)"></el-button>
            </el-tooltip>

            <el-tooltip effect="dark" content="入库" placement="top" :enterable="true" v-if="scope.row.sr_warehouse_status!=3">
              <el-button type="primary" size="mini" icon="el-icon-sold-out"
                         @click="saleDelivery(scope.row.sr_id)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="添加付款记录" placement="top" :enterable="true"
                        v-if="is_payment && scope.row.sr_payment_status!=3 && scope.row.sr_status==1">
              <el-button type="primary" size="mini" icon="el-icon-s-finance"
                         @click="addsalepay(scope.row.sr_id)"></el-button>
            </el-tooltip>
<!--            <el-tooltip effect="dark" content="作废" placement="top" :enterable="true"-->
<!--                        v-if="is_nullify && scope.row.sr_payment_status==1 && scope.row.sr_status==1">-->
<!--              <el-button type="danger" size="mini" icon="el-icon-document-delete"-->
<!--                         @click="saletoVoid(scope.row.sr_id)"></el-button>-->
<!--            </el-tooltip>-->

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

    </el-card>

  </div>
</template>
<script>
import XLSX from 'xlsx'

export default {
  data() {
    return{
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        sr_code:''
      },
      total:0,
      //页面权限
      GoodsSaleJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_payment:false,
      is_nullify:false,
      is_delivery:false,
      is_export:false,
      is_printing:false,
      //表格加载
      loading:true,
      saleList:[],
      statusList:[{s_status:0,s_statusname:'全部'},{s_status:1,s_statusname:'正常'},{s_status:2,s_statusname:'作废'}],
      //控制商品显示隐藏
      addGoodsDialogVisible:false,
      outpaylist:[{id:1,name:'未付款'},{id:2,name:'部分付款'},{id:3,name:'已付款'}],
      userlist:[],
    }
  },
  created() {
    this.getsaleList()
    this.getGoodsSaleJurisdiction()
    this.getuserList()
  },
  activated(){
    this.getsaleList()
    this.getuserList()
  },
  methods: {
    //获取权限接口
    async getGoodsSaleJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.GoodsSaleJurisdiction=res.data
      this.GoodsSaleJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if(item.m_path=='payment'){
          this.is_payment=true
        }else if(item.m_path=='nullify'){
          this.is_nullify=true
        }else if(item.m_path=='delivery'){
          this.is_delivery=true
        }else if(item.m_path=='export'){
          this.is_export=true
        }else if(item.m_path=='printing'){
          this.is_printing=true
        }
      })
    },
    //获取销售列表
    async getsaleList() {
      this.loading=true
      const {data: res} = await this.$http.get('salereturn/getList',{params:this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.saleList = res.data.data
      this.total=res.data.total
      this.loading=false
    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },

    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getsaleList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getsaleList ()
    },
    inputchange(){
      this.querInfo.page=1
      this.getsaleList()
    },
    //添加商品销售
    goAddSaleGoods(){
      this.$router.push('/goodssalereturn/addgoodssalereturn')
    },
    //跳转详情
    saledetails(id){
      console.log(id)
      this.$router.push({path: "/goodssalereturn/goodssalereturndetails", query: {editid: id,is_printing:this.is_printing}})
    },
    //入库
    saleDelivery(id){
      console.log(id)
      this.$router.push({path: "/goodssalereturn/goodssalereturnwarehouseenter", query: {editid: id}})
    },
    //添加付款记录
    addsalepay(id){
      this.$router.push({path: "/goodssalereturn/addgoodssalereturnpay", query: {editid: id}})
    },
    //作废接口
    async saletoVoid(id){
      const confirmResult = await this.$confirm(
          '此操作将作废该条记录, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消作废')
      }
      const {data:res} = await this.$http.post(`salereturn/nullify/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getsaleList()
    },
    //选择是否作废
    selectstatusList(){
      this.querInfo.page=1
      this.getsaleList()
    },
    async DownloadGoodsSale(){
      return new Promise((resolve, reject) => {
        // console.log(resolve)
        // console.log(reject)
        const {data:res} = this.$http.get('salereturn/export', {
          responseType: "blob" // 1.首先设置responseType对象格式为 blob:
        })
            .then(
                res => {
                  //resolve(res)
                  let blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel"
                  }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                  let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
                  // let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
                  // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
                  let a = document.createElement("a");
                  var date = new Date();
                  a.href = url;
                  a.download = "销售退货订单"+date.getFullYear()+"-"+ (date.getMonth()+1) +"-"+date.getDate() +".xlsx";
                  a.click();
                  // 5.释放这个临时的对象url
                  window.URL.revokeObjectURL(url);
                },
                err => {
                  resolve(err.response);
                }
            )
            .catch(error => {
              reject(error);
            });
      });

    }

  },
}
</script>
<style lang="less" scoped>
.el-col{
  margin-bottom: 20px;
}
.p_purchase_id{
  width: 100%;
}

.el-date-editor.el-input{
  width: 100%;

}
</style>
